import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import ApiService from '@/services/api.service';
import Manage from '../views/ManageCampaign.vue';
// import Campaign from '../views/Campaign.vue';
import CreateCampaign from '../views/CreateCampaign.vue';
import ViewCampaign from '../views/ViewCampaign.vue';
import SMSView from '../views/SMSView.vue';

Vue.use(VueRouter);

function checkAuth(to, from, next) {
  const tok = localStorage.getItem('comms-token');
  const tokObj = tok ? JSON.parse(Buffer.from(tok.split('.')[1], 'base64').toString()) : null;

  if (tokObj && tokObj.exp * 1000 > Date.now()) {
    next();
  } else if (to.query['24g_id']) {
    const query = to.query['24g_id'];
    ApiService.post(`/users/sso-auth?id=${query}`)
      .then((resp) => {
        const { token } = resp.data;
        localStorage.setItem('comms-token', token);
        ApiService.setToken();
        const newQuery = { ...to.query };
        delete newQuery['24g_id'];
        next({
          path: to.path,
          query: newQuery,
          replace: true,
        });
      })
      .catch((error) => {
        console.error(error);
        window.location = `${process.env.VUE_APP_LOGIN_URL}/logout.php`;
      });
  } else {
    const encodedURI = encodeURIComponent(window.location.href);
    window.location = `${process.env.VUE_APP_LOGIN_URL}/?callback=${encodedURI}`;
  }
}

const routes = [
  {
    path: '/',
    redirect: '/manage',
    name: 'Manage',
    beforeEnter: checkAuth,
  },
  {
    path: '/manage',
    name: 'Manage',
    component: Manage,
    beforeEnter: checkAuth,
  },
  {
    path: '/campaign',
    name: 'CreateCampaign',
    component: CreateCampaign,
    beforeEnter: checkAuth,
  },
  {
    path: '/campaign/:campaignID?',
    name: 'EditCampaign',
    component: CreateCampaign,
    beforeEnter: checkAuth,
  },
  {
    path: '/viewcampaign/:campaignID?',
    name: 'ViewCampaign',
    component: ViewCampaign,
    beforeEnter: checkAuth,
  },
  {
    path: '*',
    name: 'SMS',
    component: SMSView,
    beforeEnter: checkAuth,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    // Scroll to the top of the page on route navigation
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
