<template>
  <Campaign :headerTitle="'View Campaign'">
    <template v-slot:settings>
      <div class="title-sm align-left">Campaign Subject</div>
      <div class="campaign-value align-left spacer-b30">{{ subject }}</div>
      <div class="title-sm align-left">Header Image</div>
      <div class="campaign-value align-left">{{ headerImage.displayName }}</div>
    </template>
    <template v-slot:delivery-methods>
      <div
        class="campaign-value align-left spacer-b15 text-captitalize"
        v-for="(method, dIndex) in deliveryMethod"
        :key="`delivery-${dIndex}`"
      >
        {{ method }}
      </div>
    </template>
    <template v-slot:audiences>
      <div class="audience-wrapper" v-for="(audience, aIndex) in audiences" :key="`audience-${aIndex}`">
        <div class="spacer-b30">
          <div class="audience-header">Audience {{ aIndex + 1 }}</div>
          <div
            class="campaign-value"
            v-for="(recipient, rIndex) in audience.recipientKeys"
            :key="`recipient-${aIndex}-${rIndex}`"
          >
            <span class="text-bold">{{ recipientFields[recipient] }}:</span>
            {{ getRecipientValues(audience, recipient) }}
          </div>
        </div>
        <div v-for="(datapoint, dIndex) in audience.dataPoints" :key="`datapoint-${aIndex}-${dIndex}`">
          <div class="comparison-wrapper spacer-b30">
            <div class="datapoint-header">Data Point</div>
            <div class="datapoint-header">Target</div>
            <div class="datapoint">{{ datapoint.key }}</div>
            <div class="comparison">{{ datapoint.comparison }}</div>
            <div class="target-value">{{ datapoint.target }}</div>
          </div>
          <div class="target-wrapper" v-if="viewMessage(datapoint, 'hit')">
            <div class="datapoint-header spacer-b20">Target Hit Suggestion</div>
            <div class="spacer-b20">{{ datapoint.messages.hit.message }}</div>
            <div class="link spacer-b20">
              <a :href="datapoint.messages.hit.link">{{ datapoint.messages.hit.linkTitle }}</a>
            </div>
          </div>
          <div class="target-wrapper" v-if="viewMessage(datapoint, 'miss')">
            <div class="datapoint-header spacer-b20">Target Miss Suggestion</div>
            <div class="spacer-b20">{{ datapoint.messages.miss.message }}</div>
            <div class="link spacer-b20">
              <a :href="datapoint.messages.miss.link">{{ datapoint.messages.miss.linkTitle }}</a>
            </div>
          </div>
          <div class="target-wrapper" v-if="viewMessage(datapoint, 'default')">
            <div class="datapoint-header spacer-b20">Default Suggestion</div>
            <div class="spacer-b20">{{ datapoint.messages.default.message }}</div>
            <div class="link spacer-b20">
              <a :href="datapoint.messages.default.link">{{ datapoint.messages.default.linkTitle }}</a>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:schedule>
      <div class="title-md align-left">Sent</div>
      <div class="campaign-value align-left">{{ deliveredAt }}</div>
    </template>
    <template v-slot:email-preview>
      <div class="preview-title spacer-b20">
        <div class="title title-md text-left" style="margin: 0">Audience {{ previewIndex + 1 }} Preview</div>
      </div>
      <EmailPreview :emailString="previewStrings[previewIndex]" />

      <div class="preview-pagination">
        <div
          v-for="(p, pIndex) in previewStrings.length"
          :key="`preview-${pIndex}`"
          class="preview-pagination-btn"
          :class="{ active: pIndex === previewIndex }"
          @click="previewIndex = pIndex"
        ></div>
      </div>
    </template>
  </Campaign>
</template>

<script>
import ApiService from '@/services/api.service';
import Campaign from '@/components/Campaign.vue';
// import Audience from '@/components/ViewAudience.vue';
import moment from 'moment-timezone';
import EmailPreview from '@/components/EmailPreview.vue';

export default {
  components: {
    Campaign,
    // Audience,
    EmailPreview,
  },
  data() {
    return {
      audiences: [],
      campaignId: null,
      deliveredAt: '',
      // deliverAt: {
      //   date: '',
      //   time: '',
      //   timezone: '',
      // },
      previewStrings: [],
      previewIndex: 0,
      deliveryMethod: [],
      headerImage: '',
      options: {},
      recipientFields: {
        allContacts: 'All Contacts',
        corporate: 'Corporate & Field',
        regions: 'Regions',
        areas: 'Areas',
        subAreas: 'Sub Areas',
        dealerCodes: 'Dealer Codes',
        jobCodes: 'Job Code',
      },
      subject: '',
    };
  },
  async created() {
    const { campaignID } = this.$route.params;
    this.campaignId = campaignID;
    await this.getOptions();
    await this.getCampaign();
  },
  methods: {
    getRecipientValues(audience, recipient) {
      if (audience.recipientValues[recipient] !== null) {
        return audience.recipientValues[recipient].join(', ');
      }
      return '';
    },
    viewMessage(datapoint, type) {
      if (datapoint.messages && datapoint.messages[type]) {
        return datapoint.messages[type].message.length > 0;
      }

      return false;
    },

    getDateTime() {},

    async getCampaign() {
      const resp = await ApiService.get(`campaigns/${this.campaignId}`);
      const {
        campaign: { audiences, deliveryMethod, subject, deliverAt, headerId },
      } = resp.data.data;

      audiences.forEach((audience, aIndex) => {
        this.addAudience();
        this.audiences[aIndex].audienceGroupId = audience.audienceGroupId;

        audience.recipients.forEach((recipient) => {
          this.audiences[aIndex].recipientKeys.push(recipient.key);
          this.audiences[aIndex].recipientValues[recipient.key] = recipient.value;
        });

        audience.datapoints.forEach((d) => {
          if (d.key !== 'n/a') {
            const temp = this.options.dataPoints.find((value) => value.key === d.key);
            d.key = temp.kpi;
          } else {
            d.key = 'n/a';
          }
        });

        this.audiences[aIndex].dataPoints = audience.datapoints;
      });
      this.deliveryMethod = deliveryMethod;
      this.subject = subject;
      this.headerImage = this.options.headers.find((option) => option.id === headerId);
      this.deliveredAt = moment(deliverAt).format('MM/DD/YYYY hh:mm A');

      const timezones = [
        { key: 'EST', value: 'America/New_York' },
        { key: 'CST', value: 'America/Chicago' },
        { key: 'MST', value: 'America/Denver' },
        { key: 'PST', value: 'America/Los_Angeles' },
      ];

      const timezone = moment.tz.guess(deliverAt);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < timezones.length; i++) {
        if (timezones[i].value === timezone) {
          this.deliveredAt = `${this.deliveredAt} ${timezones[i].key}`;
        }
      }
      this.refreshPreviews();
    },
    async getOptions() {
      try {
        const resp = await ApiService.get('/campaigns/options');
        this.options = resp.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    addAudience() {
      this.audiences.push({
        audienceGroupId: '',
        recipientKeys: [],
        recipientValues: {
          byField: [],
          areas: [],
          regions: [],
          dealerCodes: [],
          jobCodes: [],
        },
        dataPoints: [
          {
            key: null,
            comparison: null,
            target: '0',
            messages: {
              hit: { message: '', link: '', linkTitle: '' },
              miss: { message: '', link: '', linkTitle: '' },
              default: { message: '', link: '', linkTitle: '' },
            },
          },
        ],
      });
    },
    refreshPreviews() {
      // Call error checking first
      this.previewStrings = [];
      this.previewIndex = 0;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.audiences.length; i++) {
        const request = {
          headerId: this.headerImage.id,
          subject: this.subject,
          message: '##messageHolder##',
          link: '',
        };
        ApiService.post('campaigns/email', request)
          .then((resp) => {
            // Run the replace "To:" for all the recipients too.
            // Run the replace message for each returned email preview
            const { email } = resp.data.data;
            const formattedEmail = this.getPreviewMessage(email, this.audiences[i]);

            return formattedEmail;
          })
          .then((email) => {
            this.previewStrings.push(email);
          });
        // Call the email preview api
        // Push the previews into the array
      }
    },
    getPreviewMessage(email, audience) {
      // eslint-disable-next-line no-param-reassign
      email = email.replace(/Learn more:/g, '');
      const messageType = ['hit', 'miss', 'default'];
      let message = '';

      const { recipientKeys } = audience;

      const recipients = recipientKeys.map((e) => this.recipientFields[e]);
      // eslint-disable-next-line no-param-reassign
      email = email.replace('To:', `To: ${recipients.join(', ')}`);

      audience.dataPoints.forEach((d) => {
        messageType.forEach((type) => {
          const messageFormat = `<p style='color:#CCCCCC;margin-bottom:10px;'>[##type## Message]</p>
            <p style="margin-bottom:10px;">##message##</p>
            <div style="margin-bottom:35px"><a  href='##url##'>Learn more:<span style='font-weight:bold'>##urlTitle##</span></a></div>`;

          if (d.messages && d.messages[type]) {
            const temp = messageFormat
              .replace(/##type##/g, `${type}`)
              .replace(/##message##/g, `${d.messages[type].message}`)
              .replace(/##url##/g, `${d.messages[type].link}`)
              .replace(/##urlTitle##/g, `${d.messages[type].linkTitle}`);
            message = message.concat(temp);
          }
        });
      });
      const newEmail = email.replace(/##messageHolder##/g, message.toString());

      return newEmail;
    },
  },
};
</script>

<style lang="scss" scoped>
.audience-wrapper {
  padding: 25px;
  text-align: left;
  border: 1px dotted $dividers;
}

.audience-header,
.datapoint-header {
  margin-bottom: 20px;
  font-size: 14px;
  font-stretch: expanded;
  line-height: 20px;
  color: $dark-grey;
  text-align: left;
  letter-spacing: -0.42px;
}

.datapoint-header {
  margin-top: 20px;
  // margin-bottom: 0;
}

.comparison-wrapper {
  display: grid;
  grid-template-areas:
    'titleOne titleTwo .'
    'dataPoint conditional target-value';
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 90px 90px;
  grid-row-gap: 20px;
  grid-column-gap: 30px;

  .datapoint-header {
    &:first-of-type {
      grid-area: titleOne;
    }

    &:nth-of-type(2) {
      grid-area: titleTwo;
    }
  }

  .datapoint {
    grid-area: dataPoint;
  }
  .comparison {
    grid-area: conditional;
  }

  .target-value {
    grid-area: target-value;
  }
}

.target-wrapper {
  .link {
    font-weight: bold;
  }
}

.title-md {
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.title-sm {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 15px;
  color: $medium-grey;
}

.campaign-value {
  font-size: 14px;
  line-height: 18px;
  color: $dark-grey;
}

.preview-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;

  .preview-pagination-btn {
    width: 18px;
    height: 5px;
    background-color: $dividers;

    &:not(:last-of-type) {
      margin-right: 6px;
    }

    &.active {
      background-color: black;
    }
  }
}

.text-bold {
  font-weight: bold;
}
</style>
