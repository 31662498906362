<template>
  <Campaign :headerTitle="headerTitle" @refreshPreviews="refreshPreviews" :editMode="true">
    <template v-slot:settings v-if="!viewMode">
      <Textbox
        :label="'Campaign Subject*'"
        :placeholder="'Campaign Example Title'"
        class="spacer-b40"
        v-model="subject"
        :id="'id'"
      />
      <InputSelect
        :label="'Header Image*'"
        :displayLabel="'displayName'"
        :testOptions="headerOptions"
        :multiple="false"
        v-model="headerImage"
      />
    </template>
    <template v-slot:delivery-methods v-if="!viewMode">
      <div class="align-left spacer-b15">
        <label class="checkbox">
          <input type="checkbox" id="`delivery-method-email`" :value="'email'" v-model="deliveryMethod" />
          <span>Email</span>
        </label>
      </div>
      <div class="align-left spacer-b20">
        <label class="checkbox">
          <input type="checkbox" id="`delivery-method-sms`" :value="'sms'" v-model="deliveryMethod" />
          <span>SMS</span>
        </label>
      </div>
      <div class="title title-xsm">
        Select at least one audience to select a data point. You can add multiple datapoints to each audience, or add
        another audience.
      </div>
    </template>
    <template v-slot:audiences v-if="!viewMode">
      <div class="title title-xsm">
        Select at least one audience to select a data point. You can add multiple data points to each audience, or add
        another audience.
      </div>
      <div v-for="(audience, aIndex) in audiences" :key="`audience-${aIndex}`">
        <Audience
          v-if="options"
          :recipientOptions="options"
          :audience="audiences[aIndex]"
          v-model="audiences[aIndex]"
          :audienceIndex="aIndex"
          v-on:addPoint="addDataPoint"
          :displayRemoveBtn="audiences.length > 1"
          @removeAudience="removeAudience(aIndex)"
        />
      </div>
      <div class="add-audience" @click="addAudience">
        <PlusIcon class="icon-plus" />
        Add another audience
      </div>
    </template>
    <template v-slot:schedule v-if="!viewMode">
      <div class="title title-md text-left">Schedule</div>
      <InputRadio
        :id="'later'"
        :name="'schedule'"
        :v_value="'scheduleLater'"
        :label="'Schedule Later'"
        v-model="scheduleType"
      />
      <InputRadio
        :id="'immediate'"
        :name="'schedule'"
        :v_value="'scheduleImmediate'"
        :label="'Schedule Immediate'"
        v-model="scheduleType"
      />
      <InputRadio
        :id="'draft'"
        :name="'schedule'"
        :v_value="'saveDraft'"
        :label="'Save as draft'"
        v-model="scheduleType"
      />
      <InputDatePicker
        class="datepicker"
        v-if="scheduleType === 'scheduleLater'"
        :date="deliverAt"
        style="margin-top: 32px"
      />
    </template>
    <template v-slot:test-email v-if="!viewMode">
      <div class="title title-md text-left">Test Email Address</div>
      <div class="test-delivery">
        <Textbox :label="'Test Email Address'" :placeholder="'Email Address'" v-model="testEmail" />
        <button class="test-sms-btn secondary-btn" @click="sendTestEmail">Send Email</button>
      </div>
    </template>
    <template v-slot:test-sms v-if="!viewMode">
      <div class="title title-md text-left">Test SMS</div>
      <div class="test-delivery">
        <Textbox :label="'Test SMS Phone Number'" :placeholder="'Phone Number'" v-model="testSMS" />
        <button class="test-sms-btn secondary-btn" @click="sendTestSMS">Send SMS</button>
      </div>
    </template>
    <template v-slot:submit v-if="!viewMode">
      <div class="flex-left">
        <button class="primary-btn spacer-t60" @click="submitCampaignButton">
          {{ submitButtonText }}
        </button>
      </div>
    </template>
    <template v-slot:email-preview>
      <div class="preview-title spacer-b20">
        <div class="title title-md text-left" style="margin: 0">Audience {{ previewIndex + 1 }} Preview</div>
        <span class="refresh-btn" @click="refreshPreviews">
          <RefreshIcon class="icon-refresh" />
          Refresh preview
        </span>
      </div>
      <EmailPreview :emailString="previewStrings[previewIndex]" />

      <div class="preview-pagination">
        <div
          v-for="(p, pIndex) in previewStrings.length"
          :key="`preview-${pIndex}`"
          class="preview-pagination-btn"
          :class="{ active: pIndex === previewIndex }"
          @click="previewIndex = pIndex"
        ></div>
      </div>
    </template>
  </Campaign>
</template>

<script>
import ApiService from '@/services/api.service';
import Campaign from '@/components/Campaign.vue';
import Textbox from '@/components/InputTextbox.vue';
import InputSelect from '@/components/InputMultiselect.vue';
import InputRadio from '@/components/InputRadio.vue';
import Audience from '@/components/Audience.vue';
import PlusIcon from '@/assets/svg/plus.svg';
import RefreshIcon from '@/assets/svg/refresh.svg';
import EmailPreview from '@/components/EmailPreview.vue';
import moment from 'moment-timezone';
import InputDatePicker from '../components/InputDatePicker.vue';

export default {
  components: {
    Campaign,
    Textbox,
    InputSelect,
    InputRadio,
    Audience,
    InputDatePicker,
    PlusIcon,
    RefreshIcon,
    EmailPreview,
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      e.returnValue = '';
    });
  },
  async created() {
    // this.formatCampaign();
    const { campaignID } = this.$route.params;
    const { copy } = this.$route.query;
    await this.getOptions();

    // edit mode
    if (campaignID) {
      this.campaignId = campaignID;
      await this.getCampaign();
    } else if (copy) {
      this.isCopy = true;
      this.campaignId = copy;
      const query = {};
      this.$router.replace({ query });
      await this.getCampaign();
    } else {
      this.addAudience();
    }
  },
  data() {
    return {
      isCopy: false,
      formattedAudiences: [],
      audiences: [],
      campaignId: null,
      subject: '',
      headerImage: '',
      headerOptions: [],
      testOptions: ['Test', 'Test 2', 'Test 4'],
      deliveryMethod: [],
      deliverAt: {
        date: '',
        time: '',
        timezone: '',
      },
      viewMode: false,
      excludeRecipientValue: ['allContacts', 'corporate'],
      scheduleType: 'saveDraft',
      testEmail: '',
      testSMS: '',
      options: {},
      previewStrings: [],
      previewIndex: 0,
      messageHTML: `
      <div style="color:#CCCCCC; margin-bottom: 15px;">##messageType##</div>
      <div style="margin-bottom: 15px;">##message##</div>
      `,
      recipientFields: {
        allContacts: 'All Contacts',
        corporate: 'Corporate & Field',
        regions: 'Regions',
        areas: 'Areas',
        subAreas: 'Sub Areas',
        dealerCodes: 'Dealer Codes',
        jobCodes: 'Job Code',
      },
    };
  },
  computed: {
    editMode() {
      return this.$route.name === 'EditCampaign';
    },

    scheduledStatusID() {
      const status = this.options.statuses.find((e) => e.type === 'SCHEDULED');
      return status.id;
    },
    draftStatusID() {
      const status = this.options.statuses.find((e) => e.type === 'DRAFT');
      return status.id;
    },

    headerTitle() {
      return this.editMode ? 'Edit Campaign' : 'Create New Campaign';
    },
    submitButtonText() {
      return this.editMode ? 'Save Campaign' : 'Save New Campaign';
    },
  },
  methods: {
    addDataPoint(index) {
      this.audiences[index].dataPoints.push({
        key: null,
        comparison: null,
        target: 0.0,
        messages: {
          hit: { message: null, link: null, linkTitle: null },
          miss: { message: null, link: null, linkTitle: null },
          default: { message: null, link: null, linkTitle: null },
        },
      });
    },
    addAudience() {
      this.audiences.push({
        audienceGroupId: '',
        recipientKeys: [],
        recipientValues: {
          byField: [],
          areas: [],
          regions: [],
          dealerCodes: [],
          jobCodes: [],
        },
        dataPoints: [
          {
            key: null,
            comparison: null,
            target: '0',
            messages: {
              hit: { message: '', link: '', linkTitle: '' },
              miss: { message: '', link: '', linkTitle: '' },
              default: { message: '', link: '', linkTitle: '' },
            },
          },
        ],
      });
    },
    removeAudience(index) {
      this.audiences.splice(index, 1);
    },

    refreshPreviews() {
      // Call error checking first
      this.previewStrings = [];
      this.previewIndex = 0;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.audiences.length; i++) {
        const request = {
          headerId: this.headerImage.id,
          subject: this.subject,
          message: '##messageHolder##',
          link: '',
          linkTitle: '',
        };
        ApiService.post('campaigns/email', request)
          .then((resp) => {
            // Run the replace "To:" for all the recipients too.
            // Run the replace message for each returned email preview
            const { email } = resp.data.data;
            const formattedEmail = this.getPreviewMessage(email, this.audiences[i]);

            return formattedEmail;
          })
          .then((email) => {
            this.previewStrings.push(email);
          });
        // Call the email preview api
        // Push the previews into the array
      }
    },
    async getCampaign() {
      const resp = await ApiService.get(`campaigns/${this.campaignId}`);
      const {
        campaign: { audiences, deliveryMethod, subject, deliverAt, headerId },
      } = resp.data.data;

      audiences.forEach((audience, aIndex) => {
        this.addAudience();

        if (!this.isCopy) {
          this.audiences[aIndex].audienceGroupId = audience.audienceGroupId;
        } else {
          this.audiences[aIndex].audienceGroupId = '';
        }

        audience.recipients.forEach((recipient) => {
          this.audiences[aIndex].recipientKeys.push(recipient.key);
          this.audiences[aIndex].recipientValues[recipient.key] = recipient.value;
        });

        audience.datapoints.forEach((d) => {
          const temp = this.options.dataPoints.find((value) => value.key === d.key);
          if (d.key !== 'n/a') {
            d.key = temp.kpi;
          }

          const messageTypes = ['hit', 'miss', 'default'];

          messageTypes.forEach((type) => {
            if (!d.messages[type]) {
              d.messages[type] = { message: '', link: '', linkTitle: '' };
            }
          });
        });

        this.audiences[aIndex].dataPoints = audience.datapoints;
      });

      this.deliveryMethod = deliveryMethod;
      this.subject = subject;
      this.deliverAt.date = deliverAt;
      this.deliverAt.time = moment.utc(deliverAt).format('hh:mm A');

      const timezones = [
        { key: 'EST', value: 'America/New_York' },
        { key: 'CST', value: 'America/Chicago' },
        { key: 'MST', value: 'America/Denver' },
        { key: 'PST', value: 'America/Los_Angeles' },
      ];

      this.deliverAt.timezone = moment.tz.guess(deliverAt);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < timezones.length; i++) {
        if (timezones[i].key === this.deliverAt.timezone) {
          this.deliverAt.timezone = timezones[i].value;
        }
      }

      this.headerImage = this.headerOptions.find((option) => option.id === headerId);
      this.refreshPreviews();
    },

    async getOptions() {
      try {
        const resp = await ApiService.get('/campaigns/options');
        const { headers } = resp.data.data;
        const json = JSON.stringify(headers);
        const header = JSON.parse(json);
        this.headerOptions = header;
        this.options = resp.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    getPreviewMessage(email, audience) {
      // eslint-disable-next-line no-param-reassign
      email = email.replace(/Learn more:/g, '');
      const messageType = ['hit', 'miss', 'default'];
      let message = '';

      const { recipientKeys } = audience;

      let toField = '';
      for (let i = 0; i < recipientKeys.length; i += 1) {
        const recip = this.recipientFields[recipientKeys[i]];
        const values = audience.recipientValues[recipientKeys[i]];
        let toValue = `${recip}`;
        if (values) {
          toValue += ` (${values.join(', ')})`;
        }
        if (i === 0) {
          toField += toValue;
        } else {
          toField += `, ${toValue}`;
        }
      }
      // eslint-disable-next-line no-param-reassign
      email = email.replace('To:', `To: ${toField}`);

      audience.dataPoints.forEach((d) => {
        messageType.forEach((type) => {
          const messageFormat = `<p style='color:#CCCCCC;margin-bottom:10px; text-transform:capitalize;'>[##type## Message]</p>
            <p style="margin-bottom:10px;">##message##</p>
            <div style="margin-bottom:35px">Learn more: <a  href='##url##' style="color:black"><span style='font-weight:bold'>##urlTitle##</span></a></div>`;

          if (d.messages && d.messages[type]) {
            const temp = messageFormat
              .replace(/##type##/g, `${type}`)
              .replace(/##message##/g, `${d.messages[type].message}`)
              .replace(/##url##/g, `${d.messages[type].link}`)
              .replace(/##urlTitle##/g, `${d.messages[type].linkTitle}`);
            message = message.concat(temp);
          }
        });
      });
      const newEmail = email.replace(/##messageHolder##/g, message.toString());

      return newEmail;
    },

    getCampaignSubject() {
      return new Promise((resolve, reject) => {
        if (this.subject.length > 0) {
          resolve(this.subject);
        }
        reject(new Error('Subject is required'));
      });
    },

    getCampaignHeader() {
      return new Promise((resolve, reject) => {
        if (this.headerImage !== null) {
          resolve(this.headerImage);
        }
        reject(new Error('Header image is required'));
      });
    },

    getCampaignDeliveryMethod() {
      return new Promise((resolve, reject) => {
        if (this.deliveryMethod.length > 0) {
          resolve(this.deliveryMethod);
        }

        reject(new Error('Delivery method is required'));
      });
    },

    getCampaignAudiences() {
      return new Promise((resolve, reject) => {
        const tempAudiences = JSON.parse(JSON.stringify(this.audiences));
        const value = tempAudiences.map((a) => {
          const audience = {
            audienceGroupId: a.audienceGroupId,
            datapoints: a.dataPoints.map((dp) => {
              if (!dp.key) {
                reject(new Error('Datapoint requires KPI'));
              }
              if (!dp.comparison && dp.key !== 'n/a') {
                reject(new Error('Datapoint requires comparison'));
              }

              return dp;
            }),

            recipients: a.recipientKeys.map((k) => {
              if (this.excludeRecipientValue.includes(k)) {
                return { key: k };
              }
              if (a.recipientValues[k].some((v) => v === 'Select All')) {
                return { key: k, value: this.options[k] };
              }

              return this.excludeRecipientValue.includes(k) ? { key: k } : { key: k, value: a.recipientValues[k] };
            }),
          };

          audience.datapoints.map((dp) => {
            if (dp.key === 'n/a') {
              return dp;
            }
            const temp = this.options.dataPoints.find((v) => v.kpi === dp.key);
            dp.key = temp.key;
            return dp;
          });

          return audience;
        });
        resolve(value);
      });
    },

    getCampaignDateTime() {
      return new Promise((resolve, reject) => {
        if (this.scheduleType === 'scheduleLater') {
          if (this.deliverAt.date === '') {
            reject(new Error('Delivery date is required for schedule later'));
          }

          if (this.deliverAt.time === '') {
            reject(new Error('Delivery time is required for schedule later'));
          }

          if (this.deliverAt.timezone === '') {
            reject(new Error('Delivery timezone is required for schedule later'));
          }

          const timezones = [
            { key: 'EST', value: 'America/New_York' },
            { key: 'CST', value: 'America/Chicago' },
            { key: 'MST', value: 'America/Denver' },
            { key: 'PST', value: 'America/Los_Angeles' },
          ];
          let timezone = 'America/New_York';

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < timezones.length; i++) {
            if (timezones[i].key === this.deliverAt.timezone) {
              timezone = timezones[i].value;
            }
          }

          const date = moment(this.deliverAt.date).format('MM/DD/YYYY');
          const dt = moment.tz(`${date} ${this.deliverAt.time}`, 'MM/DD/YYYY hh:mm A', `${timezone}`).utc().format();

          resolve(dt);
        }
        if (this.scheduleType === 'scheduleImmediate') {
          resolve(moment.utc().format());
        }
        resolve(null);
      });
    },

    async submitCampaign() {
      try {
        const results = await Promise.all([
          this.getCampaignSubject(),
          this.getCampaignHeader(),
          this.getCampaignDeliveryMethod(),
          this.getCampaignAudiences(),
          this.getCampaignDateTime(),
        ]);

        const campaign = {
          subject: results[0],
          headerId: results[1].id,
          deliveryMethod: results[2],
          audiences: results[3],
          deliverAt: results[4],
          statusId: this.scheduleType === 'saveDraft' ? this.draftStatusID : this.scheduledStatusID,
        };

        if (this.editMode) {
          await ApiService.put(`/campaigns/${this.campaignId}`, campaign);
          this.$notify({
            group: 'primary',
            title: '',
            text: 'Campaign successfully saved',
          });
          return this.campaignId;
        }

        try {
          const resp = await ApiService.post('/campaigns', campaign);
          this.$notify({
            group: 'primary',
            title: '',
            text: 'Campaign successfully saved',
          });
          this.campaignId = resp.data.data.campaignId;
          return resp.data.data.campaignId;
        } catch (error) {
          console.error(error);
          this.$notify({
            group: 'primary',
            type: 'error',
            title: 'Error has occurred',
            text: error,
          });
          throw new Error(error);
        }
      } catch (error) {
        this.$notify({
          group: 'primary',
          type: 'error',
          title: '',
          text: error,
        });
        console.error(error);
        throw new Error(error);
      }
    },

    async submitCampaignButton() {
      const cid = await this.submitCampaign();
      if (!this.editMode) this.$router.push(`/campaign/${cid}`);
    },

    async sendTestSMS() {
      try {
        // const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
        const regex = /^(\+?\d{1,2})?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
        if (!regex.test(this.testSMS)) {
          throw new Error('Phone number is not valid');
        }

        this.testSMS = this.testSMS.replace(/[.]|[,]|[ ]|[,]|[-]|[(]|[)]/gi, '');

        const cid = await this.submitCampaign();

        await ApiService.post('/campaigns/sendsms', { to: this.testSMS, campaignId: cid }).then(() => {
          this.$notify({
            group: 'primary',
            title: '',
            text: 'Test SMS sent',
          });
        });

        if (!this.editMode) this.$router.push(`/campaign/${cid}`);
      } catch (error) {
        console.error(error);
        this.$notify({
          group: 'primary',
          type: 'error',
          title: 'Error has occurred',
          text: error,
        });
      }
    },
    sendTestEmail() {
      Promise.all([
        this.getCampaignSubject(),
        this.getCampaignHeader(),
        this.getCampaignDeliveryMethod(),
        this.getCampaignAudiences(),
        this.getCampaignDateTime(),
      ])
        .then((results) => {
          const campaign = {
            subject: results[0],
            headerId: results[1].id,
            deliveryMethod: results[2],
            audiences: results[3],
            deliverAt: results[4],
          };
          ApiService.post('/campaigns/sendemail', { to: this.testEmail, campaign })
            .then(() => {
              this.$notify({
                group: 'primary',
                title: '',
                text: 'Test email sent',
              });
            })
            .catch((error) => {
              console.error(error);
              this.$notify({
                group: 'primary',
                type: 'error',
                title: 'Error has occurred',
                text: error,
              });
            });
        })
        .catch((error) => {
          console.error(error);
          this.$notify({
            group: 'primary',
            type: 'error',
            title: 'Error has occurred',
            text: error,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.test-delivery {
  display: flex;
  justify-content: space-between;

  ::v-deep [type='text'] {
    width: 100%;
    max-width: 314px;
  }
}
.add-audience {
  width: 100%;
  text-align: left;
  cursor: pointer;
  .icon-plus {
    display: inline;
    margin-right: 15px;
    text-align: left;
  }
}

.icon-refresh {
  width: auto;
  height: 18px;
  margin-right: 15px;
}

.refresh-btn {
  cursor: pointer;
}

.preview-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;

  .preview-pagination-btn {
    width: 18px;
    height: 5px;
    background-color: $dividers;

    &:not(:last-of-type) {
      margin-right: 6px;
    }

    &.active {
      background-color: black;
    }
  }
}
</style>
