import Vue from 'vue';
import VCalendar from 'v-calendar';
import Notifications from 'vue-notification';
import App from './App.vue';
import router from './router';
import store from './store';
import ApiService from './services/api.service';

ApiService.init();

Vue.use(VCalendar, { componentPrefix: 'vc' });
Vue.use(Notifications);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
