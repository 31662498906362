<template>
  <div class="container container-campaign">
    <div class="left-column">
      <div class="title title-lg text-left">{{ headerTitle }}</div>
      <div class="title title-md text-left">Campaign Setup</div>
      <slot name="settings"></slot>
      <slot name="view-settings"></slot>
      <div class="divider" />
      <div class="title title-md text-left">Delivery Method</div>
      <slot name="delivery-methods"></slot>
      <slot name="view-delivery-methods"></slot>
      <div class="divider" />
      <div class="title title-md text-left">Audiences and Data Points</div>
      <slot name="audiences"></slot>
      <div class="divider" v-if="editMode" />
      <slot name="test-email"></slot>
      <slot name="test-sms"></slot>
      <div class="divider" />
      <slot name="schedule"></slot>
      <slot name="submit"></slot>
    </div>
    <div class="right-column">
      <slot name="email-preview"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: String,
    editMode: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 501px 627px;
  grid-column-gap: 58px;
  width: 100%;
  padding: 0 60px 100px 100px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 40px 0;
  background-color: $dividers;
}

.text {
  &-left {
    text-align: left;
  }
}

.right-column {
  padding-top: 91px;

  .preview-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
