<template>
  <header class="header">
    <AudiIcon class="logo"></AudiIcon>
    <!-- Needs a v-if the url is anything but the home page? -->
    <router-link to="/" :disabled="!isCampaign">
      <div class="manage-btn" :class="{ isCampaign: isCampaign }">manage campaigns</div>
    </router-link>
    <!-- Needs to route to the actual create page -->

    <button class="primary-btn create-btn" @click="$router.push({ path: '/campaign' })">create new campaign</button>

    <LogoutIcon class="logout-btn" @click="logout"></LogoutIcon>
  </header>
</template>

<script>
import AudiIcon from '../assets/svg/audi_rings.svg';
import LogoutIcon from '../assets/svg/logout.svg';

export default {
  name: 'AudiHeader',
  components: { AudiIcon, LogoutIcon },
  computed: {
    isCampaign() {
      return this.$route.name === 'Campaign';
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('comms-token');
      window.location = `${process.env.VUE_APP_LOGIN_URL}/logout.php`;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1366px;
  height: 96px;
  max-height: 96px;
  margin-bottom: 20px;

  .logo {
    height: 100%;
    margin-right: auto;
  }

  .manage-btn {
    padding-bottom: 5px;
    margin-right: 35px;
    font-size: 14px;
    font-stretch: expanded;
    line-height: 17px;
    text-transform: capitalize;
    letter-spacing: -0.2px;
    border-bottom: 1px solid $dark-grey;
  }

  .isCampaign {
    color: $disabled-content;
    border: none;

    &:hover {
      color: $dark-grey;
      cursor: pointer;
    }
  }

  .create-btn {
    margin-right: 56px;
  }

  .logout-btn {
    width: 17px;
    height: auto;
    margin-right: 30px;
    cursor: pointer;
  }
}
</style>
