<template>
  <label class="textbox">
    <span class="title-label" v-if="label !== null">{{ label }}</span>
    <input invalid type="text" :placeholder="placeholder" v-model="inputValue" />
  </label>
</template>

<script>
export default {
  name: 'InputTextbox',
  props: {
    value: {
      type: [String, Number],
    },
    label: String,
    placeholder: String,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit('input', e);
      },
    },
  },
};
</script>
