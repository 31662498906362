<template>
  <div class="preview-container">
    <div class="preview">
      <div v-html="emailString"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailPreview',
  props: { emailString: String },
};
</script>

<style lang="scss" scoped>
.preview-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 548px;
  padding: 45px;
  overflow-y: scroll;
  background-color: $dividers;
}
</style>
