<template>
  <div class="wrapper">
    <div class="audience-header spacer-b20">
      Audience {{ audienceIndex + 1 }}
      <span class="remove-audience" v-if="displayRemoveBtn" @click="removeAudience">
        <XIcon class="icon-x" />Remove audience
      </span>
    </div>
    <div
      class="align-left spacer-b15"
      v-for="(value, key, aIndex) in recipientFields"
      :key="`recipientField-${audienceIndex}-${aIndex}`"
    >
      <label class="checkbox">
        <input
          type="checkbox"
          :id="`recipientField-${aIndex}-${audienceIndex}-checkbox`"
          :value="key"
          v-model="inputValue.recipientKeys"
        />
        <span>{{ value }}</span>
      </label>
      <div class="select-wrapper" v-if="hasRecipientDropdown(key)">
        <InputSelect
          :testOptions="['Select All', ...recipientOptions[key]]"
          :multiple="true"
          v-model="inputValue.recipientValues[key]"
        />
      </div>
    </div>
    <div v-if="inputValue.recipientKeys.length > 0">
      <div v-for="(data, dIndex) in inputValue.dataPoints" :key="`dataPoints-${dIndex}-${audienceIndex}`">
        <div class="comparison-wrapper spacer-b20">
          <div class="datapoint-header">Data Point</div>
          <div class="datapoint-header" v-if="inputValue.dataPoints[dIndex].key !== 'n/a'">Target</div>
          <InputSelect
            class="datapoint"
            :testOptions="dataPointOptions"
            :multiple="false"
            v-model="inputValue.dataPoints[dIndex].key"
          />

          <InputSelect
            class="comparison"
            :testOptions="comparisonOptions"
            :multiple="false"
            v-model="inputValue.dataPoints[dIndex].comparison"
            v-if="inputValue.dataPoints[dIndex].key !== 'n/a'"
          />
          <Textbox
            style="margin-top: auto"
            class="target-value"
            :label="''"
            :placeholder="'0.0'"
            v-model="inputValue.dataPoints[dIndex].target"
            v-if="inputValue.dataPoints[dIndex].key !== 'n/a'"
          />
        </div>
        <div class="target-wrapper" v-if="!defaultMessage(data.key)">
          <div class="datapoint-header">Target Hit Suggestion</div>
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'Hit Message'"
            v-model="inputValue.dataPoints[dIndex].messages.hit.message"
          />
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'URL Suggestion'"
            v-model="inputValue.dataPoints[dIndex].messages.hit.link"
          />
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'URL Suggestion Title'"
            v-model="inputValue.dataPoints[dIndex].messages.hit.linkTitle"
          />
        </div>
        <div class="target-wrapper" v-if="!defaultMessage(data.key)">
          <div class="datapoint-header">Target Miss Suggestion</div>
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'Miss Message'"
            v-model="inputValue.dataPoints[dIndex].messages.miss.message"
          />
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'URL Suggestion'"
            v-model="inputValue.dataPoints[dIndex].messages.miss.link"
          />
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'URL Suggestion Title'"
            v-model="inputValue.dataPoints[dIndex].messages.miss.linkTitle"
          />
        </div>
        <div class="target-wrapper">
          <div class="datapoint-header">Default Messaging</div>
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'Default Message'"
            v-model="inputValue.dataPoints[dIndex].messages.default.message"
          />
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'URL Suggestion'"
            v-model="inputValue.dataPoints[dIndex].messages.default.link"
          />
          <Textbox
            class="spacer-b20"
            :label="''"
            :placeholder="'URL Suggestion Title'"
            v-model="inputValue.dataPoints[dIndex].messages.default.linkTitle"
          />
        </div>
      </div>
      <div class="add-datapoint" @click="addDataPoint">
        <PlusIcon class="icon-plus" />
        Add another data point
      </div>
    </div>
  </div>
</template>

<script>
//! Add datapoint method in this component
import InputSelect from '@/components/InputMultiselect.vue';
import Textbox from '@/components/InputTextbox.vue';
import XIcon from '@/assets/svg/x.svg';
import PlusIcon from '@/assets/svg/plus.svg';

export default {
  components: { InputSelect, Textbox, PlusIcon, XIcon },
  props: {
    audience: Object,
    audienceIndex: Number,
    displayRemoveBtn: Boolean,
    recipientOptions: Object,
  },
  data() {
    return {
      testOptions: ['n/a', 'Test', 'Test 2', 'Test 4'],
      comparisonOptions: ['>', '>=', '=', '<=', '<'],
      excludeRecipientDropdown: ['allContacts', 'corporate'],
      recipientFields: {
        allContacts: 'All Contacts',
        corporate: 'Corporate & Field',
        regions: 'Regions',
        areas: 'Areas',
        subAreas: 'Sub Areas',
        dealerCodes: 'Dealer Codes',
        jobCodes: 'Job Code',
      },
    };
  },
  created() {},

  computed: {
    // formattedDatapointOptions() {
    //   const temp = this.datapointOptions.dataPoints;
    //   temp.push({ key: 'n/a', kpi: 'n/a' });
    //   return temp;
    // },

    dataPointOptions() {
      if (!this.recipientOptions.dataPoints) return [];
      const arr = this.recipientOptions.dataPoints.map((e) => e.kpi);

      arr.push('n/a');
      return arr;
    },
    inputValue: {
      get() {
        return this.audience;
      },
      set(e) {
        this.$emit('input', e);
      },
    },
  },
  methods: {
    recipientOptionsAdditional(key) {
      const arr = ['Select All', ...this.recipientOptions[key]];
      return arr;
    },

    defaultMessage(key) {
      return key === 'n/a';
    },

    addDataPoint() {
      this.$emit('addPoint', this.audienceIndex);
    },

    removeAudience() {
      this.$emit('removeAudience');
    },

    hasRecipientDropdown(key) {
      return !this.excludeRecipientDropdown.includes(key) && this.inputValue.recipientKeys.includes(key);
    },
  },
};
</script>

<style lang="scss" scoped>
.audience-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .remove-audience {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $error;
    cursor: pointer;
  }
}

.wrapper {
  padding: 25px;
  margin: 0 0 20px;
  border: 1px dotted $dividers;
}

.select-wrapper {
  padding: 0 40px 0;
}

.comparison-wrapper {
  display: grid;
  grid-template-areas:
    'titleOne titleTwo .'
    'dataPoint conditional target-value';
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 90px 90px;
  grid-column-gap: 30px;

  .datapoint-header {
    &:first-of-type {
      grid-area: titleOne;
    }

    &:nth-of-type(2) {
      grid-area: titleTwo;
    }
  }

  .datapoint {
    grid-area: dataPoint;
  }
  .comparison {
    grid-area: conditional;
  }

  .target-value {
    grid-area: target-value;
  }
}

.datapoint-header {
  text-align: left;
}

.icon-plus {
  display: inline;
  margin-right: 15px;
  text-align: left;
}

.icon-x {
  width: 15px;
  height: 15px;
  margin-right: 15px;
}

.add-audience {
  cursor: pointer;
}

.add-datapoint {
  width: 100%;
  text-align: left;
  cursor: pointer;
}
</style>
