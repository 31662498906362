import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
    this.setToken();
  },

  setToken() {
    // const token = process.env.VUE_APP_TEMP_TOKEN;
    const token = localStorage.getItem('comms-token');
    if (token) Vue.axios.defaults.headers.common.Authorization = token;
    else delete Vue.axios.defaults.headers.common.Authorization;
  },

  get(resource, params) {
    return Vue.axios.get(`${resource}`, { params });
  },

  post(resource, params, queryParams) {
    return Vue.axios.post(`${resource}`, params, queryParams);
  },
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource, params) {
    return Vue.axios.delete(`${resource}`, { data: params });
  },
};

export default ApiService;
