<template>
  <div id="app">
    <AudiHeader v-if="showHeader.includes($route.name)"></AudiHeader>
    <router-view />
    <notifications class="comms-notification" group="primary" width="100%" position="bottom left" :duration="10000">
    </notifications>
  </div>
</template>

<script>
import AudiHeader from './components/AudiHeader.vue';

export default {
  name: 'App',
  components: { AudiHeader },
  data() {
    return {
      showHeader: ['Manage', 'EditCampaign', 'ViewCampaign', 'CreateCampaign'],
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type

@import './assets/fonts/_fonts.css';
@import '@/assets/scss/_theme.scss';
body {
  margin: 0;
}
#app {
  // width: 1366px;
  max-width: 1366px;
  margin: 0 auto;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Audi', Arial, Helvetica, sans-serif !important;
  color: #2c3e50;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.comms-notification {
  z-index: 10000 !important;
  max-width: 375px !important;
  margin-bottom: 40px;
  .vue-notification-template {
    max-width: 325px;
    padding: 20px 25px;
    margin: 0 25px 10px;
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    color: white;
    background-color: $dark-grey;
    border: none;
    box-shadow: 0 2px 6px rgba(black, 0.16);
    .notification-content {
      display: flex;
      align-items: center;
      img {
        max-width: 70px;
        max-height: 70px;
        margin-right: 20px;
      }
    }
    &.info {
      color: $dark-grey;
    }
    &.success {
      color: $dark-grey;
      background-color: $success;
    }
    &.error {
      color: white;
      background-color: $error;
    }
  }
  .notification {
    &-enter-active,
    &-leave-active {
      transition: transform 1s;
    }
    &-enter,
    &-leave-to {
      transform: translateX(-100%);
    }
    &-move {
      transition: all 0.5s;
    }
  }
}
</style>
