<template>
  <div class="textbox spacer-t40">
    <span class="title-label">{{ label }}</span>
    <Multiselect
      :multiple="multiple"
      :close-on-select="!multiple"
      :selectLabel="''"
      :options="testOptions"
      v-model="inputValue"
      :placeholder="'select'"
      :track-by="id"
      :label="displayLabel"
      :searchable="true"
      :showLabels="false"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'InputMultiselect',
  components: { Multiselect },
  props: {
    value: null,
    multiple: Boolean,
    testOptions: { type: Array, default: () => [] },
    id: String,
    displayLabel: String,
    label: String,
  },
  data() {
    return {
      defaultArr: [],
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit('input', e);
      },
    },
  },
};
</script>
