<template>
  <label class="radio">
    <span class="radio-input">
      <input type="radio" :id="id" :name="name" :value="inputValue" v-model="inputValue" :checked="checked" />
      <span class="radio-control"></span>
    </span>
    <span class="radio-label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'InputRadio',
  props: {
    checked: Boolean,
    id: String,
    name: String,
    value: String,
    label: String,
    v_value: String,
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', this.v_value);
      },
    },
  },
};
</script>
