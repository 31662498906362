<template>
  <div class="preview-container">
    <div class="preview">
      <div v-html="emailString"></div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api.service';

export default {
  name: 'EmailPreview',
  data() {
    return {
      emailString: '',
    };
  },
  async created() {
    const { path } = this.$route;
    const shortCode = path.substring(1, path.length);
    const resp = await ApiService.post('/campaigns/getmsg', { shortCode });
    this.emailString = resp.data.data.html;
  },
};
</script>

<style lang="scss" scoped>
.preview-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
</style>
