<template>
  <div class="container">
    <h1>Campaigns</h1>
    <div class="campaign-row">
      <div class="row row-header">campaign subject</div>
      <div class="row row-header">delivery method</div>
      <div class="row row-header">audience</div>
      <div class="row row-header">status</div>
      <div class="row row-header">date &amp; time sent</div>
      <div class="row row-header">actions</div>
    </div>
    <!-- will be a v-for depending on the campaign response structure -->
    <div class="campaign-row" v-for="(row, index) in campaigns" :key="index">
      <div class="campaign-row">
        <div class="row">{{ row.subject }}</div>
        <div class="row">{{ row.deliveryMethod.join() }}</div>
        <div class="row audience-overflow">{{ formattedAudience(row) }}</div>
        <div class="row">{{ row.campaign_status.type }}</div>
        <div class="row">{{ getDateTime(row.scheduleAt) }}</div>
        <div class="row row-action">
          <router-link
            :to="{ path: `/viewcampaign/${row.id}` }"
            v-if="row.campaign_status.type === 'SENDING' || row.campaign_status.type === 'SENT'"
          >
            <span class="action-wrapper">
              <ViewIcon class="icon icon-view"></ViewIcon>
              <span class="action-desc">View</span>
            </span>
          </router-link>
          <router-link
            :to="{ path: `/campaign/${row.id}` }"
            v-if="row.campaign_status.type === 'DRAFT' || row.campaign_status.type === 'SCHEDULED'"
          >
            <span class="action-wrapper">
              <EditIcon class="icon icon-edit"></EditIcon>
              <span class="action-desc">Edit</span>
            </span>
          </router-link>
          <router-link :to="{ path: `/campaign?copy=${row.id}` }">
            <span class="action-wrapper">
              <DuplicateIcon class="icon"></DuplicateIcon>
              <span class="action-desc">Duplicate</span>
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <!-- pagination stuff -->
    <div class="pagination" v-if="pageCount > 0">
      <Carat class="carat left-carat" @click="decrementPage" />
      <Paginate
        v-model="pageNumber"
        :page-count="pageCount"
        :prev-text="''"
        :next-text="''"
        :container-class="'paginate'"
        :clickHandler="getCampaigns"
      />
      <Carat class="carat right-carat" @click="incrementPage" />
    </div>
  </div>
</template>

<script>
import EditIcon from '@/assets/svg/edit.svg';
import DuplicateIcon from '@/assets/svg/duplicate.svg';
import ViewIcon from '@/assets/svg/view.svg';
import ApiService from '@/services/api.service';
import moment from 'moment-timezone';
import Paginate from 'vuejs-paginate';
import Carat from '@/assets/svg/left_carat.svg';

export default {
  name: 'ManageCampaign',
  components: { EditIcon, DuplicateIcon, Paginate, Carat, ViewIcon },
  mounted() {
    this.getCampaigns();
    if (Object.keys(this.$route.query).length > 0) {
      this.$router.replace({ query: {} });
    }
    // this.$notify({
    //   group: 'primary',
    //   type: 'error',
    //   title: 'Important message',
    //   text: 'Please fix the highlighted errors.',
    // });
  },
  data() {
    return {
      campaigns: [],
      options: null,
      totalCampaigns: 0,
      pageNumber: 0,
    };
  },
  computed: {
    pageCount() {
      return Math.floor(this.totalCampaigns / 6);
    },
  },
  methods: {
    getFriendlyAudienceName(audience) {
      switch (audience) {
        case 'allContacts':
          return 'All Contacts';
        case 'jobCodes':
          return 'Job Codes';
        case 'dealerCodes':
          return 'Dealer Codes';
        case 'subAreas':
          return 'Sub Areas';
        case 'corporate':
          return 'Corporate & Field';
        default:
          return audience;
      }
    },

    formattedAudience(data) {
      const finalData = {};
      const { audiences } = data;

      audiences.forEach((audience) => {
        const { recipients } = audience;

        recipients.forEach((recip) => {
          const { key, value } = recip;
          if (finalData[key] && Array.isArray(finalData[key]) && Array.isArray(value)) {
            finalData[key] = finalData[key].concat(value);
          } else if (Array.isArray(value)) {
            finalData[key] = value;
          } else {
            finalData[key] = true;
          }
        });
      });

      const strings = [];
      const keys = Object.keys(finalData);

      keys.forEach((key) => {
        let str = this.getFriendlyAudienceName(key.toString());
        if (Array.isArray(finalData[key])) {
          str += ` (${finalData[key].length} selected)`;
        }
        strings.push(str);
      });

      let finalStr = '';

      for (let i = 0; i < strings.length; i += 1) {
        if (i !== 0) finalStr += ', ';
        finalStr += strings[i];
      }

      return finalStr;
    },

    incrementPage() {
      this.pageNumber += 1;
      this.pageNumber = Math.min(this.pageNumber, this.pageCount);
      this.getCampaigns();
    },

    decrementPage() {
      this.pageNumber -= 1;
      this.pageNumber = Math.max(this.pageNumber, 1);
      this.getCampaigns();
    },

    getDateTime(dateTime) {
      if (!dateTime) {
        return 'N/A';
      }

      return moment(dateTime).format('MM/DD/YYYY hh:mm A');
    },
    getCampaigns() {
      ApiService.get(`campaigns?page=${this.pageNumber}&limit=6`, {})
        .then(({ data }) => {
          this.totalCampaigns = data.data.totalCampaigns;
          this.campaigns = data.data.campaigns;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getOptions() {
      ApiService.get('/campaigns/options')
        .then((resp) => {
          this.options = resp.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 100px;

  .campaign-row {
    display: grid;
    grid-template-columns: 250px 140px 200px 135px 200px 181px;
    grid-column-gap: 12px;
    text-transform: capitalize;

    .row {
      padding: 20px 0;
      font-size: 15px;
      line-height: 19px;
      text-align: left;
      border-bottom: 1px solid $dividers;
      &-header {
        font-weight: bold;
        color: black;
        border-top: 3px solid black;
      }

      &.audience-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.row-action {
  .action-wrapper {
    .icon {
      margin-right: 9px;
    }

    &:not(:last-child) {
      padding-right: 12px;
      border-right: 1px solid $dividers;
    }

    &:last-child {
      padding-left: 12px;
    }
  }
}

.action-desc {
  font-size: 14px;
  line-height: 17px;
  color: black;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginate {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  padding: 60px 0 60px;
  margin: 0;
  font-size: 16px;
  list-style: none;
  ::v-deep {
    font-family: 'Audi', Arial, Helvetica, sans-serif;
    a {
      padding: 5px;
    }
    .active {
      background-color: transparent !important;
      border-bottom: 2px solid $dark-grey;
    }
    li {
      margin: 0 15px !important;
    }
  }
}

.carat {
  width: 20px;
  height: auto;
  cursor: pointer;
}

.right-carat {
  transform: rotate(180deg);
}
</style>
