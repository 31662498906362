<template>
  <div class="datepicker-wrapper">
    <div class="header header-date">Send Date</div>
    <div class="header header-time">Send Time</div>
    <div class="header header-zone">Time Zone</div>
    <DatePicker
      id="sendDate"
      :calendar-button="true"
      calendar-class="date-calendar"
      input-class="date-input"
      wrapper-class="date-wrapper"
      v-model="dateProp.date"
    >
      <template v-slot:prevIntervalBtn>
        <Carat class="carat-icon-left carat-icon" />
      </template>
      <template v-slot:nextIntervalBtn>
        <Carat class="carat-icon-right carat-icon" />
      </template>
      <template v-slot:calendarBtn>
        <CalendarIcon class="date-icon" />
      </template>
    </DatePicker>
    <VueTimePicker format="hh:mm A" v-model="dateProp.time" />
    <Multiselect
      :close-on-select="true"
      :selectLabel="''"
      :options="timezoneCodes"
      v-model="dateProp.timezone"
      placeholder="EST"
      :showLabels="false"
    />
  </div>
</template>

<script>
import DatePicker from '@sum.cumo/vue-datepicker';
import CalendarIcon from '@/assets/svg/date_icon.svg';
import Carat from '@/assets/svg/left_carat.svg';
import VueTimePicker from 'vue2-timepicker/src/vue-timepicker.vue';
import Multiselect from 'vue-multiselect';
import '@sum.cumo/vue-datepicker/dist/Datepicker.css';

export default {
  name: 'CampaignDatePicker',
  components: { DatePicker, CalendarIcon, Carat, VueTimePicker, Multiselect },
  props: {
    date: Object,
  },
  mounted() {
    this.dateProp.timezone = this.selectedTimeZone;
  },
  data() {
    return {
      timezoneOptions: [
        { key: 'EST', value: 'America/New_York' },
        { key: 'CST', value: 'America/Chicago' },
        { key: 'MST', value: 'America/Denver' },
        { key: 'PST', value: 'America/Los_Angeles' },
      ],
    };
  },
  computed: {
    timezoneCodes() {
      return this.timezoneOptions.map((v) => v.key);
    },
    selectedTimeZone() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.timezoneOptions.length; i++) {
        if (this.timezoneOptions[i].value === this.dateProp.timezone) {
          return this.timezoneOptions[i].key;
        }
      }
      return '';
    },
    dateProp: {
      get() {
        return this.date;
      },
      set(value) {
        this.$emit('update:date', value);
      },
    },
  },
};
</script>

<style lang="scss">
.vdp-datepicker__calendar {
  &::v-deep {
    font-family: 'Audi', Arial, Helvetica, sans-serif;
  }
}

.dateWrapper > div:first-of-type {
  display: flex;
  flex-direction: row-reverse !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
}

.cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: none !important;
  border-color: $medium-grey !important;
}

.vdp-datepicker__calendar .today {
  background-color: transparent;
}

.cell.selected:hover {
  color: white !important;
  background: $medium-grey !important;
  border-color: white !important;
}

span.cell.day.selected {
  position: relative;
  font-weight: bold;

  &::after {
    position: absolute;
    bottom: 5px;
    left: 50%;
    box-sizing: border-box;
    width: 22px;
    content: '';
    border-bottom: 2px solid $dark-grey;
    transform: translateX(-50%);
  }

  background: none;
}

.dateInputField {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0;
  // margin-bottom: 20px;margin-bottommargin-bottom
  font-size: 14px;
  border: none;
  border-bottom: 1px solid $medium-grey;
}
</style>

<style lang="scss" scoped>
.datepicker-wrapper {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 160px 1fr 120px;
  grid-column-gap: 30px;
}

.header {
  font-size: 12px;
  line-height: 15px;
  color: $dark-grey;
  text-align: left;
}

.carat-icon {
  width: 20px;
  height: 30px;
  margin-top: 10px;

  &-right {
    transform: rotate(180deg);
  }
}

.date-icon {
  width: 22.5px;
  height: auto;
  margin-top: auto;
  margin-left: 17px;
  vertical-align: bottom;
}
</style>

<style lang="scss">
// Date Picker Styling
.date-wrapper {
  display: flex;
}

.date-wrapper > div:first-of-type {
  display: flex;
  flex-direction: row-reverse !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
}

.cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: none !important;
  border-color: $medium-grey !important;
}

.vdp-datepicker__calendar .today {
  background-color: transparent;
}

.cell.selected:hover {
  color: white !important;
  background: $medium-grey !important;
  border-color: white !important;
}

span.cell.day.selected {
  position: relative;
  font-weight: bold;

  &::after {
    position: absolute;
    bottom: 5px;
    left: 50%;
    box-sizing: border-box;
    width: 22px;
    content: '';
    border-bottom: 2px solid $dark-grey;
    transform: translateX(-50%);
  }

  background: none;
}

.date-input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0;
  font-family: 'Audi', Arial, Helvetica, sans-serif;
  // margin-bottom: 20px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid $medium-grey;
}

// End date picker styling

// Time picker styling
.time-picker {
  margin-top: auto;
}

.time-picker::v-deep * {
  font-family: 'audi', Arial, Helvetica, sans-serif !important;
}

li.active {
  background-color: $dark-grey !important;
}
.vue__time-picker input.display-time {
  &::v-deep * {
    font-family: 'Audi', Arial, Helvetica, sans-serif;
  }

  width: 100%;
  padding-left: 0;
  font-family: 'Audi', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #666;
  border: none;
  border-bottom: 1px solid $dark-grey;
}

.display-time {
  padding-bottom: 25px;
}

// End time picker styling
</style>
