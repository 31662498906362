import ApiService from '@/services/api.service';

export default {
  state: {
    campaign: {},
  },
  getters: {
    campaign: (state) => state.campaign,
    id: (state) => state.campaign.id,
    subject: (state) => state.campaign.subject,
    header: (state) => state.campaign.header,
    deliveryMethod: (state) => state.campaign.deliveryMethod,
    deliverAt: (state) => state.campaign.deliverAt,
    audiences: (state) => state.campaign.audiences || [],
    recipients: (state) => (index) => state.campaign.audiences[index].recipients,
    dataPoints: (state) => (index) => state.campaign.audiences[index].dataPoints,
  },
  actions: {
    getCampaign: ({ commit }, { id }) =>
      new Promise((resolve, reject) => {
        ApiService.get(`campaigns/${id}`)
          .then((resp) => {
            commit('SET_CAMPAIGN', resp.data.data.campaign);
            resolve(resp);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
  },
  mutations: {
    SET_RECIPIENTS: (state, { recipients, index }) => {
      state.campaign.audiences[index].recipients = recipients;
    },
    SET_DATAPOINTS: (state, { dataPoints, dataIndex, index }) => {
      state.campaign.audiences[index].dataPoints[dataIndex] = dataPoints;
    },

    SET_SUBJECT: (state, subject) => {
      state.campaign.subject = subject;
    },
    SET_HEADER: (state, header) => {
      state.campaign.header = header;
    },
    SET_CAMPAIGN: (state, campaign) => {
      state.campaign = campaign;
    },
    createCampaign(state) {
      state.campaign = {
        subject: null,
        headerImage: null,
        status: null,
        deliverAt: null,
        deliveryMethod: [],
        audiences: [
          {
            recipients: [],
            dataPoints: [
              {
                key: null,
                comparison: null,
                target: 0,
                message: {
                  hit: { message: null, link: null, linkTitle: null },
                  miss: { message: null, link: null, linkTitle: null },
                  default: { message: null, link: null, linkTitle: null },
                },
              },
            ],
          },
        ],
      };
    },

    removeAudience(state, index) {
      state.campaign.audiences.splice(index, 1);
    },

    removeDataPoint(state, audiencesIndex, dataPointIndex) {
      state.campaign.audiences[audiencesIndex].dataPoints.splice(dataPointIndex, 1);
    },
    addAudience(state) {
      const audience = {
        recipients: [],
        dataPoints: [
          {
            key: null,
            comparison: null,
            target: null,
            message: {
              hit: { message: null, link: null, linkTitle: null },
              miss: { message: null, link: null, linkTitle: null },
              default: { message: null, link: null, linkTitle: null },
            },
          },
        ],
      };
      state.audiences.push(audience);
    },

    addDataPoint(state, audienceIndex) {
      state.campaign.audiences[audienceIndex].dataPoints.push({
        key: null,
        comparison: null,
        target: null,
        message: {
          hit: { message: null, link: null, linkTitle: null },
          miss: { message: null, link: null, linkTitle: null },
          default: { message: null, link: null, linkTitle: null },
        },
      });
    },
  },
};
